@font-face {
  font-family: Poppins;
  src: local("Poppins-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Poppins;
  src: local("Poppins-SemiBold.ttf");
  font-weight: 600;
}
